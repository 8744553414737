class LocalSotrage {
  set = (key, value) => {
    window.localStorage.setItem && window.localStorage.setItem(key, value)
  }
  get = (key, value) => {
    return window.localStorage.getItem && window.localStorage.getItem(key, value)
  }
  remove = key => {
    window.localStorage.removeItem && window.localStorage.removeItem(key)
  }
}

export const localStorage = new LocalSotrage()

/**
 * 
 * @param {*} _vue vue实例
 * @param {*} lang 语言
 */
export const changeLang = (_vue, lang = 'en') => {
  if (_vue && _vue.$i18n) {
    _vue.$i18n.locale = lang
    localStorage.set('i18nLang', lang)
  }
}

/**
 * 根据参数名获取url查询参数
 * @param {*} queryName 参数名
 * @returns
 */
export const getQueryByName = queryName => {
  const list = window.location.href.split('?')
  let search = ''
  if (list && Array.isArray(list) && list.length > 1) {
    search = list[1]
  }
  const reg = new RegExp('(^|&)'+ queryName +'=([^&]*)(&|$)')
  const r = search.match(reg) //？后面的参数，并匹配正则
  if (r !== null) {
    return unescape(r[2])
  }

  return null
}