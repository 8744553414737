const message = {
  msg_1: '技术参数',
  msg_2: '室内停车场，无他之选',
  msg_3: '会车礼让',
  msg_4: '跨越减速带',
  msg_5: '跨层',
  msg_6: '手自一体，充分利用',
  msg_7: '方向盘内嵌屏幕',
  msg_8: '自动补给',
  msg_9: '配套APP',
  msg_10: '超高效率，省时降本',
  msg_11: '清洁效率',
  msg_12: '管理效率',
  msg_13: '会车礼让',
  msg_14: '做清洁，不影响老板们停车',
  msg_15: '保洁工作第一原则是不能影响主业务，对前后来车都能礼让，无论是在拐弯处还是在T型/十字路口，都能选择最合适的停靠点，不影响业主和顾客的正常停车，还能做好清洁本职工作。',
  msg_16: '前后礼让',
  msg_17: '拐弯处礼让',
  msg_18: 'T型/十字路口礼让',
  msg_19: '场景策略',
  msg_20: '跨越减速带',
  msg_21: '跨越斜坡',
  msg_22: '自我保护，不给使用者添麻烦',
  msg_23: '从B1到B3，不用人来转场',
  msg_24: '室内停车场环境复杂，稍有不慎就会损坏机器，在经过减速带/安全门/下水槽时能主动减速并抬起作业机构，保护自己，就是保护使用者的业绩输出。',
  msg_25: '斜坡几乎是所有室内停车场都会遇到的场景，能自主跨越斜坡，便有了在不同层自由穿梭的能力，即使是跨层任务，也不再需要人工转场。',
  msg_26: '手自一体',
  msg_27: '自动',
  msg_28: '手动',
  msg_29: '自动，是台高度自动化的无人洗地机',
  msg_30: '手动，也是台杰出的驾驶式洗地机',
  msg_31: '不仅可以自动无人清洁，自动处理清洁途中的各种人车障碍，甚至连排污水、加清水、充电也可实现自动化。',
  msg_32: '自动模式下难免有无法覆盖的区域，人工清洁也会是清洁的一部分。一台驾驶式洗地机，可以让使用者长时间清洁时不易疲劳，保障清洁品质。',
  msg_33: '人机协同',
  msg_34: '各取所长，配合才是王道',
  msg_35: '人机融合设计，方向盘内嵌触控屏，物理启停按钮，让人工操作到自动清洁之间无缝切换；协作和异常可通过移动端传达，让人机协作变得自然而然。',
  msg_36: '效率超群',
  msg_37: '充1次电，续航5小时，覆盖10000平',
  msg_38: '日覆盖面积可达17500平，对于大面积的场景尤其有意义，意味着可花更少的成本更少的时间达到同样甚至更高的清洁覆盖频次。',
  msg_39: '数字化管理',
  msg_40: '工作结果从不可知到可知，从可知到量化',
  msg_41: '配套移动端APP，将业务结果数字化呈现在手中，为保洁的精细化运营提供了一把好用的标尺。',
}

export default message