const message = {
  msg_1: 'Safe and see more comprehensively',
  msg_2: 'Through the sensor design all over the body, the machine can fully perceive the environment and ensure the safety of the machine when operating in the human-machine inclusive environment.',
  msg_3: `Lidar`,
  msg_4: '360 degrees 100 meters',
  msg_5: 'Depth camera',
  msg_6: '80 degrees 3 meters',
  msg_7: 'HD camera',
  msg_8: '120 degrees',
  msg_9: 'Ultrasound',
  msg_10: '50 degrees 1 meter',
  msg_11: 'Safe touch',
  msg_12: 'Professional, understand the scene better',
  msg_13: 'Through a full range of perception technology, the machine can identify objects and make plans to ensure that the machine can effectively deal with problems, taking into account the operating experience and work efficiency.',
  msg_14: 'Object recognition',
  msg_15: 'Distinguish between people, cars, and ordinary obstacles-according to different objects',
  msg_16: 'Realize exclusive execution strategy (waiting to bypass obstacles, actively avoiding obstacles, directly bypassing obstacles)',
  msg_17: 'Environmental recognition',
  msg_18: 'Passable areas, speed bumps, wheel blocking levers, etc.-according to different environmental characteristics',
  msg_19: 'Achieve exclusive leaping strategy (normal operation, speed reduction leaping, obstacle circumvention)',
  msg_20: 'Efficient, digital twin',
  msg_21: 'Through a full range of perception technology, the machine can identify objects and make plans to ensure that the machine can effectively deal with problems, taking into account the operating experience and work efficiency.',
}

export default message