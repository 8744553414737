import detail_zh from './detail/detail_zh'
import detail_en from './detail/detail_en'
// home
import home_zh from './home/home_zh'
import home_en from './home/home_en'
// 关于 about
import about_zh from './about/about_zh'
import about_en from './about/about_en'
// contact
import contact_zh from './contact/contact_zh'
import contact_en from './contact/contact_en'
// join
import join_zh from './join/join_zh'
import join_en from './join/join_en'
// news
import news_zh from './news/news_zh'
import news_en from './news/news_en'
// product
import product_zh from './product/product_zh'
import product_en from './product/product_en'
// product-spec
import productSpec_zh from './productSpec/productSpec_zh'
import productSpec_en from './productSpec/productSpec_en'
// tech
import tech_zh from './tech/tech_zh'
import tech_en from './tech/tech_en'
// footer
import footer_zh from './footer/footer_zh'
import footer_en from './footer/footer_en'
// nav
import nav_zh from './nav/nav_zh'
import nav_en from './nav/nav_en'
// download
import download_zh from './download/download_zh'
import download_en from './download/download_en'

const messages = {
  zh: {
    detail: detail_zh,
    about: about_zh,
    home: home_zh,
    contact: contact_zh,
    news: news_zh,
    product: product_zh,
    productSpec: productSpec_zh,
    join: join_zh,
    tech: tech_zh,
    footer: footer_zh,
    nav: nav_zh,
    download: download_zh,
  },
  en: {
    detail: detail_en,
    about: about_en,
    home: home_en,
    contact: contact_en,
    news: news_en,
    product: product_en,
    productSpec: productSpec_en,
    join: join_en,
    tech: tech_en,
    footer: footer_en,
    nav: nav_en,
    download: download_en,
  },
}

export default messages