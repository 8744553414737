<template>
  <div id="app" class="fullScreen">
    <!-- 非管理页面、非媒体详情页面 -- 不展示 导航条 -->
    <nav-bar />
    <div id="wrapper">
      <router-view />
    </div>
    <!-- 非管理页面、非媒体详情页面 -- 不展示 导航条 -->
    <Footer />
  </div>
</template>

<script>
import NavBar from './components/navBar'
import Footer from './components/footer'
import { localStorage } from './common/utils'

export default {
  components: {
    NavBar,
    Footer,
  },
  data () {
    return {
    }
  },
  created () {
    const lang = localStorage.get('i18nLang')
    this.$store.commit('setLang', lang)
  },
  mounted() {
    this.resizeFont()
  },
  methods: {
    resizeFont () {
      const docEl = document.documentElement
      const resizeEvt = 'orientationchange' in window ? 'orientationchange': 'resize'
      const recalc = () => {
        const clientWidth = docEl.clientWidth
        if (!clientWidth) return

        if (clientWidth > 1000) {
          docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px'
        } else {
          docEl.style.fontSize = 100 * (clientWidth / 750) + 'px'
        }
      };
  
  
      if (!document.addEventListener) return
      window.addEventListener(resizeEvt, recalc, false)
      document.addEventListener('DOMContentLoaded', recalc, false)
    },
  },
}
</script>

<style>
#app {
  width: 100%;
  background: #40444A;
  font-size: .22rem;
  font-family: 'SourceHanSansCNRegular';
  min-height: 100vh;
  color: #F2F3F4;
}

@media (min-width: 1001px) {
  #wrapper {
    min-height: calc(100vh - .8rem - 4.8rem);
  }
}
</style>
