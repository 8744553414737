import Vue from 'vue'
import App from './App.vue'
import router from 'common/router'
import VueI18n from 'vue-i18n'
import messages from './constants/index'
import { localStorage } from 'common/utils'
import animate from 'animate.css'
// import 'lib-flexible'
import './styles/index.less'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueI18n)

Vue.use(animate)


// 格式化全局语音设置
const i18n = new VueI18n({
  locale: localStorage.get('i18nLang') || 'zh', // 设置地区
  messages, // 设置地区信息
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
