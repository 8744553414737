const message = {
  msg_1: 'BOTIFY is positioned as a tool intelligent technology service provider. Through intelligent technology represented by robots, tools are empowered, and the "machine + human" approach is used to solve the shortage of labor in labor-intensive industries, the quality of operations is not high, and the cost continues to increase. The problem, so that the work of practitioners is more dignified.',
  msg_2: 'Cleaning pain points',
  msg_3: `Can't recruit people`,
  msg_4: 'Uncontrollable',
  msg_5: 'The cleaning industry has been playing a game with the labor market. In the 1920s, the biggest challenge was not only the rigid rise of labor costs, but also the extreme shortage of cleaning human resources. Long-term boring, harsh, and low-paying jobs have made the cleaning industry unable to replenish fresh blood. The lack of staff has become a common problem in the cleaning industry, and service consistency is difficult to solve. In addition, the lack of digital management tools has increased the burden on cleaning managers, and it is difficult to control the grassroots cleaning staff.',
  msg_6: 'Unmanned solution',
  msg_7: 'BOTIFY Huizhi is committed to solving the problems of service consistency, manpower shortage, rigid increase in labor cost, and lack of digital management tools in the cleaning industry. It uses technology to empower tools and organizations to provide customers with unmanned and digital cleaning solutions.',
  msg_8: 'Unmanned washing machine',
  msg_9: 'Efficient floor cleaning in all indoor scenes',
  msg_10: 'Indoor parking, no other choice',
  msg_11: 'Hand-in-one, make full use of',
  msg_12: 'Super high efficiency, saving time and cost',
  msg_13: 'understand more',
  msg_14: 'Read the original',
}

export default message