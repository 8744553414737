const message = {
  msg_1: '"ify" in English means "...ification". For example, beautify means to beautify and simplify means to simplify. "Bot" is short for "robot" in English. The meaning of Botify is "robotization", which represents the long-cherished wish of the Huizhi team, which can give the soul to tools, and all tools can be robotized. If the hardware is the body of the robot, and the software is the soul of the robot, then BOTIFY() is a function method created by the Huizhi team, a method to make the tool robotized and intelligent, and what is contained in the parentheses is Huizhi The team is committed to changing everything.',
  msg_2: 'Huizhi is positioned as a tool intelligent service provider, empowering tools, through "machine + human",',
  msg_3: 'Solve the problem of manpower shortage in labor-intensive industries.',
  msg_4: 'Vision',
  msg_5: 'Continuously meet the needs and challenges of customers and become a trustworthy and continuous growth company.',
  msg_6: 'Mission',
  msg_7: 'Make tools smarter and make work easier.',
  msg_8: 'Values',
  msg_9: 'Professional focus, empathy and win-win.',
  msg_10: 'Wanwuyun leads the investment',
  msg_11: 'Wanwuyun leads the investment',
  msg_12: 'Founded by a professional service robot team that was established for 8 years, Wanwuyun (formerly Vanke Property) led the investment, a marriage of market and technology.',
  msg_13: 'Dozens of robot patent layouts',
  msg_14: 'Dozens of robot patent layouts',
  msg_15: 'Have a complete own technical system.',
  msg_16: "The world's first to release a basement cleaning robot",
  msg_17: "The world's first to release a basement cleaning robot",
  msg_18: 'Focus on space service scenarios and intelligent positioning tools; use technology to serve property companies to enter the AI era, improve customer satisfaction, and empower people to engage in more valuable work.'
}

export default message