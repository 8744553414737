const message = {
  msg_1: '"ify"在英文中是“…化”的的涵义，例如beautify是美化的意思，simplify是简化的意思。“bot”在英文中是“机器人”的简称。Botify的涵义即是“机器人化”，这代表了汇智团队的夙愿，可以为工具赋予灵魂，工具皆可机器人化。如果硬件是机器人的身体，软件是机器人的灵魂，那BOTIFY()就是汇智团队创造的一个函数方法，一个让工具可以机器人化、智能化的方法，而传参括号所包含的正是汇智团队致力于改变的万物。',
  msg_2: '汇智定位于工具智能化服务商，为工具赋能，通过“机器+人”，',
  msg_3: '解决劳动密集型行业人力短缺问题。',
  msg_4: '愿景',
  msg_5: '不断满足客户的需求与挑战，成为一家值得信赖，持续成长的企业。',
  msg_6: '使命',
  msg_7: '使工具更智能，让工作更轻松。',
  msg_8: '价值观',
  msg_9: '专业专注，共情共赢。',
  msg_10: '万物云领投',
  msg_11: '万物云领投',
  msg_12: '由成立8年的专业服务机器人团队创立，万物云（原万科物业）领投，市场和技术的联姻。',
  msg_13: '数十个机器人专利布局',
  msg_14: '数十个机器人专利布局',
  msg_15: '拥有完整的自有技术体系。',
  msg_16: '全球率先发布地库清洁机器人',
  msg_17: '全球率先发布地库清洁机器人',
  msg_18: '聚焦空间服务场景，定位工具智能化；通过科技服务物业企业进入AI时代，提升客户满意度，赋能人从事更有价值的工作。',
}

export default message